import LotsDone from './LotsDone';
import Wheel from './Wheel';

const Hero = () => {
  return (
    <div className="container mx-auto flex flex-col items-center justify-center mt-5 lg:mt-10">
      <Wheel
        radius={125}
        partitions={6}
        labels={['30$', '25$', '20$', '10$', '15$', '5$']}
      />
      {/* <div className="relative">
        <img
          src="/assets/images/offers/offers.png"
          className="w-full"
          alt="offers"
        />
        <div
          onClick={() => window.open('https://my.damu.co/register', '_blank')}
          className="h-[120px] w-[120px] lg:h-[163px] lg:w-[163px] bg-green rounded-full text-[32px] lg:text-[46px] text-black custom-font-bold flex items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          Click
        </div>
      </div> */}
      <LotsDone />
    </div>
  );
};

export default Hero;
