import WheelBg from './wheel.png';
import SpinIcon from './spin.png';
import { useEffect, useState } from 'react';

interface CartesianCoords {
  x: number;
  y: number;
}

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
): CartesianCoords => {
  const angleInRadians = (angleInDegrees - 90) * (Math.PI / 180.0);
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const describeArc = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
): string => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  return [
    `M ${start.x} ${start.y}`,
    `A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`,
    `L ${x} ${y}`,
    'Z',
  ].join(' ');
};

interface PartitionedCircleProps {
  radius?: number;
  partitions?: number;
  labels?: string[];
}

const Wheel: React.FC<PartitionedCircleProps> = ({
  radius = 100,
  partitions = 4,
  labels = [],
}) => {
  const centerX = 150; // center x of the wheel
  const centerY = 150; // center y of the wheel
  const anglePerPartition = 360 / partitions; // angle for each partition
  const [rotation, setRotation] = useState(0); // current rotation angle
  const [spinning, setSpinning] = useState(false); // whether the wheel is spinning
  const [spinSpeed, setSpinSpeed] = useState(0); // current speed of spin

  useEffect(() => {
    let spinTimeout: NodeJS.Timeout;

    if (spinning) {
      const spin = () => {
        setRotation((prev) => prev + spinSpeed);

        // Gradually decrease spin speed
        if (spinSpeed > 0) {
          setSpinSpeed((prev) => Math.max(prev - 0.05, 0)); // Slow down
          spinTimeout = setTimeout(spin, 100); // Keep spinning
        } else {
          // Stop spinning with a random additional rotation
          const randomOffset = Math.floor(Math.random() * 360); // Random angle between 0 and 360
          setRotation((prev) => prev + randomOffset); // Add random rotation
          setSpinning(false); // Finally stop spinning
        }
      };
      spin();
    }

    return () => clearTimeout(spinTimeout); // Clean up timeout
  }, [spinning, spinSpeed]);

  const startSpin = () => {
    const durationInSeconds = 10; // Desired spin duration
    const initialSpinSpeed =
      (360 * durationInSeconds) / (durationInSeconds * 10); // Adjusted to control total rotations
    setRotation(0); // Reset rotation
    setSpinSpeed(initialSpinSpeed); // Set initial spin speed
    setSpinning(true); // Start spinning
  };

  return (
    <div className="relative">
      <svg
        width="649"
        height="649"
        viewBox="0 0 300 300"
        style={{
          position: 'relative',
          transform: `rotate(${rotation}deg)`,
          transition: 'transform 0.1s ease-out',
        }}
      >
        {Array.from({ length: partitions }).map((_, i) => {
          const startAngle = i * anglePerPartition;
          const endAngle = (i + 1) * anglePerPartition;
          const label = labels[i] || '';

          const angleForText = startAngle + anglePerPartition / 2;
          const textRadius = radius * 0.65;
          const textCoords = polarToCartesian(
            centerX,
            centerY,
            textRadius,
            angleForText,
          );

          return (
            <g key={i}>
              <path
                d={describeArc(centerX, centerY, radius, startAngle, endAngle)}
                fill={i % 2 === 0 ? '#FFFFFF' : '#9437FE'}
              />
              <text
                x={textCoords.x}
                y={textCoords.y}
                fill={i % 2 === 0 ? '#9437FE' : '#FFFFFF'}
                fontSize="20"
                fontWeight="bold"
                textAnchor="middle"
                alignmentBaseline="middle"
                transform={`rotate(${angleForText + 270}, ${textCoords.x}, ${
                  textCoords.y
                })`}
              >
                {label}
              </text>
            </g>
          );
        })}
      </svg>
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `url(${WheelBg})`,
          backgroundSize: 'contain',
          width: '649px',
          height: '649px',
        }}
      ></div>
      <img
        onClick={startSpin}
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full shadow-2xl shadow-black cursor-pointer"
        src={SpinIcon}
        alt="spin"
        height={150}
        width={150}
      />
    </div>
  );
};

export default Wheel;
